.container {
    @apply flex flex-row flex-wrap border border-solid border-grey-4 p-5 md:gap-[2%] mt-1 mb-3;
    :global {
        
        .form-group {
            @apply w-full md:w-[32%];

            &.message {
                @apply w-full mb-0;

                textarea {
                    @apply h-20;
                }
            }

            &.phone {

                input {
                    -moz-appearance: textfield;

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                } 
            }

            &.referrer_url,
            &.landing_url,
            &.form_uuid {
                @apply hidden;
            }

        }

        label {
            @apply block uppercase text-xs leading-[0.875rem] tracking-[0.03125rem] font-bold text-grey-1 mb-2 relative;
        }
    }
}


